import React from 'react';

import stars from 'assets/icons/stars.svg'
import styles from './styles.module.scss';

const TestimonialsItem = ({ authorImg, authorName, authorPosition, text }) => {
    return (
        <div className={ styles.testimonials_item }>
            <div className={ styles.testimonials_item__stars }>
                <img src={ stars } alt="img"/>
            </div>
            <p className={ styles.testimonials_item__text }>
                { text }
            </p>
            <div className={ styles.testimonials_author }>
                <div className={ styles.testimonials_author__image }>
                    <img src={ authorImg } alt="img"/>
                </div>
                <div className={ styles.testimonials_author__info }>
                    <p className={ styles.testimonials_author__name }>
                        { authorName }
                    </p>
                    <p className={ styles.testimonials_author__position }>
                        { authorPosition }
                    </p>
                </div>
            </div>
        </div>
    );
}

export { TestimonialsItem };
