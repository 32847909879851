import React, { useState, useEffect } from "react";
import { getDatabase, ref, push, update, onValue } from "firebase/database";
import { useNavigate, useParams } from "react-router";
import "./style.css";
import { toast } from "react-toastify";
import { Box, Button, InputAdornment } from "@mui/material";
import { Icon } from "@mui/material";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import TopAppBar from "../../../layout/appbar";
import CloseDatePicker from "../../DatePicker";
import OrderFormSelect from "../../select";
import NameInput from "../../inputLabel/NameInput"; // dont remove this
import { getAuth, onAuthStateChanged } from "firebase/auth";

// Assets
import userIcon from "../../../assets/icons/svgs/userIcon.svg";
import emailIcon from "../../../assets/icons/svgs/emailIcon.svg";
import phoneCallIcon from "../../../assets/icons/svgs/phoneCallIcon.svg";
import moment from "moment";
import Spinner from "utils/Spinner/Spinner";

const initializeOrderDetails = {
  propertyAddress: "",
  closeDate: "",
  priority: "High",
  sellerName: "",
  sellerCellNo: "",
  sellerEmail: "",
  buyerName: "",
  buyerCellNo: "",
  buyerEmail: "",
  buyerAgentName: "",
  buyerAgentCellNo: "",
  buyerAgentEmail: "",
  sellerAgentName: "",
  sellerAgentCellNo: "",
  sellerAgentEmail: "",
  propertyAddTime: moment().format("MMM Do YY"),
  sellerDate: moment().format("MMM Do YY"),
  sellerTime: moment().format("LT"),
};

const NewOrderForm = ({ editForm }) => {
  const addUserIcon = (
    <Icon>
      <img src={userIcon} alt="userIcon" />
    </Icon>
  );
  const addEmailIcon = (
    <Icon>
      <img src={emailIcon} alt="userIcon" />
    </Icon>
  );
  const addPhoneCallIcon = (
    <Icon>
      <img src={phoneCallIcon} alt="phoneCallIcon" />
    </Icon>
  );

  const navigate = useNavigate();
  const params = useParams();
  const [orderDetails, setOrderDetails] = useState(initializeOrderDetails);
  const [isLoading, setIsLoading] = useState(false);
  const [userState, setUserState] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);

  const handleFormSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const formValues = { ...orderDetails };

    formValues.closeDate = formValues?.closeDate?.$d
      ? new Date(formValues.closeDate?.$d).toDateString()
      : typeof formValues.closeDate !== "object" && !!formValues.closeDate
      ? formValues.closeDate
      : "";

    if (editForm) {
      formValues.propertyAddTime = moment().format("MMM Do YY");
      formValues.sellerDate = moment().format("MMM Do YY");
      formValues.sellerTime = moment().format("LT");

      setOrderDetails(formValues);

      const db = getDatabase();
      const docRef = ref(db, "orders-details/" + params.orderId);
      update(docRef, formValues);
      toast.success("Order Details Updated");
      setIsLoading(false);
      navigate("/dashboard/title-orders");

      return;
    }

    formValues.userId = userState.uid;
    if (!formValues.userId) {
      setIsLoading(false);

      return;
    }

    if (
      formValues.propertyAddress === "" ||
      formValues.closeDate === "" ||
      formValues.priority === "" ||
      formValues.sellerName === "" ||
      formValues.sellerCellNo === "" ||
      formValues.sellerEmail === "" ||
      formValues.buyerName === "" ||
      formValues.buyerCellNo === "" ||
      formValues.buyerEmail === "" ||
      formValues.buyerAgentName === "" ||
      formValues.buyerAgentEmail === "" ||
      formValues.sellerAgentName === "" ||
      formValues.sellerAgentCellNo === "" ||
      formValues.sellerAgentEmail === ""
    ) {
      toast.error("Please enter all fields");
      setIsLoading(false);
      return;
    }

    try {
      async function writeOrderData(obj) {
        const db = getDatabase();
        await push(ref(db, "orders-details/"), obj);
        navigate("/dashboard/title-orders");
      }

      await writeOrderData(formValues);

      toast.success("New order added successfully");
    } catch (err) {
      console.log(err);
      toast.error("Uh Oh! Something went wrong. Please try again later");
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setOrderDetails({
      ...orderDetails,
      [name]: value,
    });
  };

  const handleCloseDateChange = (value) => {
    setOrderDetails((prevState) => ({ ...prevState, closeDate: value }));
  };

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserState(user);
      }
    });
  }, []);

  useEffect(() => {
    if (
      orderDetails.propertyAddress === "" ||
      (orderDetails?.closeDate?.$d === undefined &&
        (orderDetails?.closeDate === "" || orderDetails?.closeDate === null)) ||
      orderDetails.priority === "" ||
      orderDetails.sellerName === "" ||
      orderDetails.sellerCellNo === "" ||
      orderDetails.sellerEmail === "" ||
      orderDetails.buyerName === "" ||
      orderDetails.buyerCellNo === "" ||
      orderDetails.buyerEmail === "" ||
      orderDetails.buyerAgentName === "" ||
      orderDetails.buyerAgentEmail === "" ||
      orderDetails.sellerAgentName === "" ||
      orderDetails.sellerAgentCellNo === "" ||
      orderDetails.sellerAgentEmail === ""
    ) {
      setIsFormValid(false);
    } else {
      setIsFormValid(true);
    }
  }, [orderDetails]);

  useEffect(() => {
    if (!params.orderId) return;
    setIsLoading(true);

    const db = getDatabase();

    const docsRef = ref(db, "orders-details/" + params.orderId);

    onValue(docsRef, (snapshot) => {
      const data = snapshot.val();
      setOrderDetails(data);
      setIsLoading(false);
    });
  }, [params]);

  return (
    <React.Fragment>
      <TopAppBar title={params.orderId ? "Edit Order" : "Open a New Order"} />
      <form onSubmit={handleFormSubmit} className="new_order_wrap">
        <Box display={"flex"} flexDirection={"column"} className="neworder_top">
          <Typography className="fw-800" variant="h5" component="h2">
            Order Details
          </Typography>
          <Typography className="fw-700" variant="h6" component="p">
            Please enter details about this order
          </Typography>
        </Box>
        <Box
          mt={2}
          display={"flex"}
          justifyContent={"space-between"}
          flexWrap={"wrap"}
        >
          <Box className="full_input" display={"flex"} flexDirection={"column"}>
            <label htmlFor="standard-multiline-static">Property address*</label>
            <TextField
              id="standard-multiline-static"
              required
              className="neworder_form_input form_field"
              rows={20}
              value={orderDetails.propertyAddress}
              name="propertyAddress"
              onChange={handleInputChange}
            />
          </Box>
          <Box className="date_wrap" display={"flex"} alignItems={"center"}>
            <CloseDatePicker
              closeDate={orderDetails.closeDate}
              changeEvent={handleCloseDateChange}
            />
            <OrderFormSelect
              priority={orderDetails.priority}
              changeEvent={handleInputChange}
            />
          </Box>
        </Box>
        <Box className="dashed-divider"></Box>
        <Box className="fields_wrap" display={"flex"} flexDirection={"column"}>
          <Typography
            mt={2}
            mb={2}
            className="fw-800"
            variant="h5"
            component="h5"
          >
            Seller Name
          </Typography>
          <Box
            className="fw_1"
            flexWrap={"wrap"}
            display={"flex"}
            alignItems={"center"}
          >
            <TextField
              label="Seller name"
              className="od_input"
              required
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {addUserIcon}
                  </InputAdornment>
                ),
              }}
              type="text"
              id="seller-name"
              value={orderDetails.sellerName}
              name="sellerName"
              onChange={handleInputChange}
            />
            <TextField
              label="Cell No"
              className="od_input"
              required
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {addEmailIcon}
                  </InputAdornment>
                ),
              }}
              type="text"
              id="seller-cell"
              value={orderDetails.sellerCellNo}
              name="sellerCellNo"
              onChange={handleInputChange}
            />
            <TextField
              label="Email Address"
              className="od_input"
              required
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {addPhoneCallIcon}
                  </InputAdornment>
                ),
              }}
              type="email"
              id="seller-email"
              value={orderDetails.sellerEmail}
              name="sellerEmail"
              onChange={handleInputChange}
            />
          </Box>
        </Box>
        <Box className="fields_wrap" display={"flex"} flexDirection={"column"}>
          <Typography
            mb={2}
            mt={2}
            className="fw-800"
            variant="h5"
            component="h5"
          >
            Buyer
          </Typography>
          <Box
            className="fw_1"
            flexWrap={"wrap"}
            display={"flex"}
            alignItems={"center"}
          >
            <TextField
              label="Buyer Name"
              className="od_input"
              required
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {addUserIcon}
                  </InputAdornment>
                ),
              }}
              type="text"
              id="buyer-name"
              value={orderDetails.buyerName}
              name="buyerName"
              onChange={handleInputChange}
            />
            <TextField
              label="Cell No"
              className="od_input"
              required
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {addEmailIcon}
                  </InputAdornment>
                ),
              }}
              type="text"
              id="buyer-cell"
              value={orderDetails.buyerCellNo}
              name="buyerCellNo"
              onChange={handleInputChange}
            />
            <TextField
              label="Email Address"
              className="od_input"
              required
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {addPhoneCallIcon}
                  </InputAdornment>
                ),
              }}
              type="email"
              id="buyer-email"
              value={orderDetails.buyerEmail}
              name="buyerEmail"
              onChange={handleInputChange}
            />
          </Box>
        </Box>
        <Box className="fields_wrap" display={"flex"} flexDirection={"column"}>
          <Typography
            mb={2}
            mt={2}
            className="fw-800"
            variant="h5"
            required
            component="h5"
          >
            Buyer Agent
          </Typography>
          <Box
            className="fw_1"
            flexWrap={"wrap"}
            display={"flex"}
            alignItems={"center"}
          >
            <TextField
              label="Buyer’s Agent Name"
              className="od_input"
              required
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {addUserIcon}
                  </InputAdornment>
                ),
              }}
              type="text"
              id="buyer-agent-name"
              value={orderDetails.buyerAgentName}
              name="buyerAgentName"
              onChange={handleInputChange}
            />
            <TextField
              label="Cell No"
              className="od_input"
              required
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {addEmailIcon}
                  </InputAdornment>
                ),
              }}
              type="text"
              id="buyer-agent-cell"
              value={orderDetails.buyerAgentCellNo}
              name="buyerAgentCellNo"
              onChange={handleInputChange}
            />
            <TextField
              label="Email Address"
              required
              className="od_input"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {addPhoneCallIcon}
                  </InputAdornment>
                ),
              }}
              type="email"
              id="buyer-agent-email"
              value={orderDetails.buyerAgentEmail}
              name="buyerAgentEmail"
              onChange={handleInputChange}
            />
          </Box>
        </Box>
        <Box className="fields_wrap" display={"flex"} flexDirection={"column"}>
          <Typography
            mb={2}
            mt={2}
            className="fw-800"
            variant="h5"
            component="h5"
          >
            Seller Agent
          </Typography>
          <Box
            className="fw_1 minWidth-100"
            flexWrap={"wrap"}
            display={"flex"}
            alignItems={"center"}
          >
            <TextField
              label="Seller’s Agent Name"
              className="od_input"
              required
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {addUserIcon}
                  </InputAdornment>
                ),
              }}
              type="text"
              id="seller-agent-name"
              value={orderDetails.sellerAgentName}
              name="sellerAgentName"
              onChange={handleInputChange}
            />
            <TextField
              label="Cell No"
              className="od_input"
              required
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {addEmailIcon}
                  </InputAdornment>
                ),
              }}
              type="text"
              id="seller-agent-cell"
              value={orderDetails.sellerAgentCellNo}
              name="sellerAgentCellNo"
              onChange={handleInputChange}
            />
            <TextField
              label="Email Address"
              className="od_input"
              required
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {addPhoneCallIcon}
                  </InputAdornment>
                ),
              }}
              type="email"
              id="seller-agent-email"
              value={orderDetails.sellerAgentEmail}
              name="sellerAgentEmail"
              onChange={handleInputChange}
            />
          </Box>
        </Box>
        <Box mt={5} width={"100%"} display={"flex"} justifyContent={"flex-end"}>
          <Button
            disabled={isLoading || !isFormValid}
            type="submit"
            className={`${isFormValid ? "text-white bg-green" : ""}  fw-700`}
            sx={{ padding: "5px 25px", fontSize: "1rem" }}
          >
            {isLoading ? (
              <Spinner loader={true} />
            ) : editForm ? (
              "Save Changes"
            ) : (
              "Submit"
            )}
          </Button>
        </Box>
      </form>
    </React.Fragment>
  );
};

export default NewOrderForm;
