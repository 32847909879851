import React, { useLayoutEffect } from "react";
import "./style.css";
import ComingSoon from "../../utils/loader/ComingSoon";

const Contact = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <ComingSoon />
    </React.Fragment>
  );
};

export default Contact;
