import React, { useLayoutEffect } from "react";
import { Box } from "@mui/material";
import ComingSoon from "../../utils/loader/ComingSoon";

const CalenderScreen = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box>
      <ComingSoon />
    </Box>
  );
};

export default CalenderScreen;
