import React from 'react';
import { useForm, ValidationError } from '@formspree/react';

import { Container } from '../Container';
import styles from './styles.module.scss';

// 
const EarlyAccess = () => {
    const [state, handleSubmit] = useForm('EarlyAccessForm', {
        data: {
            _subject: 'Get early access Closr'
        }
    });

    return (
        <section className={styles.early_access}>
            <Container>
                <div className={ styles.early_access__row }>
                    <div className={ styles.early_access__content }>
                        <p className={ styles.early_access__title }>Get early access</p>
                        <span className={ styles.early_access__subtitle }>Sign up to learn more</span>
                    </div>
                    <form className={ styles.early_access__form } onSubmit={ handleSubmit }>
                        <input
                            id="email"
                            type="email"
                            name="Email"
                            placeholder="Your Email"
                            required={ true }
                            className={ styles.early_access__input }
                        />
                        <button type="submit" disabled={ state.submitting }
                                className={ styles.early_access__btn }>Submit
                        </button>
                        <div className={ styles.error }>
                            <ValidationError field="Email" prefix="Email" errors={ state.errors }/>
                        </div>
                        {
                            state.succeeded && <div className={ styles.success }>Success</div>
                        }
                    </form>
                </div>
            </Container>
        </section>
    );
}

export { EarlyAccess };
