import React from 'react';

import { Container } from '../Container';
import user1 from 'assets/images/testimonials/01.jpg';
import user2 from 'assets/images/testimonials/02.jpg';
import user3 from 'assets/images/testimonials/03.jpg';
import styles from './styles.module.scss';
import { Subtitle } from '../Subtitle';
import { TestimonialsItem } from './TestimonialsItem';
import { Title } from '../Title';

const Testimonials = () => {
    return (
        <section className={ styles.testimonials }>
            <Container>
                <Title text="What Customers Are Saying"/>
                <Subtitle text="Delivering value to the borrower and the title company"/>
                <div className={ styles.testimonials__row }>
                    <TestimonialsItem
                        text="Closr helps you better understand where each file is and what tasks are outstanding."
                        authorImg={ user1 }
                        authorName="Regina Miles"
                        authorPosition="Attorney"
                    />
                    <TestimonialsItem
                        text="We went from 90% manual, offline file management to 100% digital in about 2 weeks."
                        authorImg={ user2 }
                        authorName="Jamie Costanza"
                        authorPosition="Closing Manager"
                    />
                    <TestimonialsItem
                        text="Closr makes me more efficient and helps me keep our clients on schedule!"
                        authorImg={ user3 }
                        authorName="Andrea Pelzer"
                        authorPosition="Title Agent"
                    />
                </div>
            </Container>
        </section>
    );
}

export { Testimonials };
