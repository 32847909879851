import React, { useState, useLayoutEffect } from "react";
import "./style.css";
import { Link } from "react-router-dom";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputLabel from "@mui/material/InputLabel";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import Stack from "@mui/material/Stack";
import { FormControl } from "@mui/material";
import { useFormik } from "formik";
import { database, auth } from "../../../config/firebaseConfig";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { ref, set } from "firebase/database";
import * as yup from "yup";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "../../../utils/Spinner/Spinner";
import CircularProgress from "@mui/material/CircularProgress";

const theme = createTheme();

export default function SignUp({ isUser, isLoading }) {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let navigate = useNavigate();
  let location = useLocation();
  const { from } = location.state || {
    from: { pathname: "/dashboard/overview" },
  };
  let [loading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  let formik = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: yup.object().shape({
      fullName: yup
        .string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Required"),
      email: yup.string().email("Invalid email").required("Required"),
      password: yup
        .string()
        .min(6, "password is too Short!")
        .max(20, "password is too Long!")
        .required("Password is required"),

      confirmPassword: yup
        .string()
        .test("passwords-match", "Passwords must match", function (value) {
          return this.parent.password === value;
        }),
    }),
    onSubmit: (value) => {
      setLoading(true);
      createUserWithEmailAndPassword(auth, value.email, value.password)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          let userData = {
            name: value.fullName,
            email: value.email,
            userId: user.uid,
            // role: role,
          };

          set(ref(database, `/users/${user.uid}`), userData)
            .then((res) => {
             
              navigate("/dashboard/overview");
              setLoading(false);
              toast.success("signed up successfully");
            })
            .catch((err) => {
              setLoading(false);

              toast.error("Email or password incorrect");
            });
          // ...
        })
        .catch((error) => {
          if (error.code === "auth/email-already-in-use") {
            toast.error("Email is already registered");
          } else {
            toast.error(error.message);
          }
          console.log(error);

          setLoading(false);
        });
    },
  });

  //
  if (isLoading) return <Spinner />;
  if (isUser) return <Navigate to={from?.pathname} replace />;

  return (
    <ThemeProvider theme={theme}>
      <Container className="form-wrap" component="main">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box
            // component="form"
            noValidate
            // onSubmit={formik.handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoComplete="given-name"
                  name="fullName"
                  onChange={formik.handleChange}
                  value={formik.values.fullName}
                  // required
                  fullWidth
                  id="fullName"
                  label="Username"
                  autoFocus
                />
                {formik.touched.fullName && formik.errors.fullName ? (
                  <div className="error_msg">{formik.errors.fullName}</div>
                ) : null}
              </Grid>

              <Grid item xs={12}>
                <FormControl sx={{ width: "100%" }} variant="outlined">
                  <TextField
                    // required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />
                </FormControl>
                {formik.touched.email && formik.errors.email ? (
                  <div className="error_msg">{formik.errors.email}</div>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                {/* <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  
                /> */}
                <FormControl sx={{ width: "100%" }} variant="outlined">
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <OutlinedInput
                    id="password"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
                {formik.touched.password && formik.errors.password ? (
                  <div className="error_msg">{formik.errors.password}</div>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <FormControl sx={{ width: "100%" }} variant="outlined">
                  <InputLabel htmlFor="confirmPassword">
                    Confirm Password
                  </InputLabel>
                  <OutlinedInput
                    id="confirmPassword"
                    onChange={formik.handleChange}
                    value={formik.values.confirmPassword}
                    type={showConfirmPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownConfirmPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Confirm Password"
                  />
                </FormControl>
                {formik.touched.confirmPassword &&
                formik.errors.confirmPassword ? (
                  <div className="error_msg">
                    {formik.errors.confirmPassword}
                  </div>
                ) : null}
              </Grid>
              {/* <Grid item xs={12}>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <label htmlFor="uploadImage">Upload Profile Image</label>
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                  >
                    <input
                      hidden
                      id="uploadImage"
                      accept="image/*"
                      type="file"
                    />
                    <PhotoCamera />
                  </IconButton>
                </Stack>
              </Grid> */}
            </Grid>
            <Button
              type="submit"
              fullWidth
              // variant="contained"
              className="bg--purple-blue text-white"
              sx={{ mt: 3, mb: 2 }}
              onClick={formik.handleSubmit}
              disabled={loading}
            >
              {loading ? <CircularProgress /> : "Sign up"}
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link className="form-link" to="/login" variant="body2">
                  {"Already have an account? Sign in"}
                </Link>
              </Grid>
              {/* <Grid item xs>
                <Link to="/signup" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid> */}
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
