import React from 'react';

import { Container } from '../Container';
import { Modal } from '../Modal';
import arrow from 'assets/icons/Vector.svg';
import styles from './styles.module.scss';
import { Link } from 'react-router-dom';

const Header = () => {
    const [isBurgerActive, setIsBurgerActive] = React.useState(false);
    const [isModalActive, setIsModalActive] = React.useState(false);

    isBurgerActive ? document.body.classList.add('lock') : document.body.classList.remove('lock');

    return (
        <header className={ styles.header }>
            <Modal isActive={ isModalActive } setIsActive={ setIsModalActive }/>
            <Container>
                <div className={ styles.header_row }>
                    <nav className={ styles.header_menu }>
                        <ul className={ isBurgerActive ? styles.header_menu__list__active : styles.header_menu__list }>
                            <li className={ styles.header_menu__link }>
                                <a href="#">Home</a>
                            </li>
                            <li className={ styles.header_menu__link }>
                                <a href="#">Product</a>
                            </li>
                            <li className={ styles.header_menu__link }>
                                <a href="#">Pricing</a>
                            </li>
                            <li className={ styles.header_menu__link }>
                                <a href="#">Contact</a>
                            </li>
                        </ul>
                    </nav>
                    <div className={ isBurgerActive ? styles.header_actions__active : styles.header_actions }>
                        <Link to="/login" className={ styles.header_actions__login }>Login</Link>
                        <button className={ styles.header_actions__signup } onClick={ () => setIsModalActive(true) }>
                            Sign Up
                            <img src={ arrow } alt="arrow img"/>
                        </button>
                    </div>
                    <div className={ isBurgerActive ? styles.header_icon__active : styles.header_icon }
                         onClick={ () => setIsBurgerActive(!isBurgerActive) }>
                        <span></span>
                    </div>
                </div>
            </Container>
        </header>
    );
}

export { Header };
