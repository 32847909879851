import * as React from "react";
import { useNavigate } from "react-router";
import "./style.css";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
// Spinner
import CircularProgress from "@mui/material/CircularProgress";

// Assets
import avatar from "../../assets/images/pngs/avatar.png";
import { auth, database } from "../../config/firebaseConfig";
import { onAuthStateChanged } from "firebase/auth";
import { ref, onValue } from "firebase/database";

export default function TopAppBar({ title }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  let [userDetail, setUserDetail] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const menuId = "primary-search-account-menu";

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  React.useEffect(() => {
    const getUser = () => {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          const uid = user.uid;
          let dbRef = ref(database, `users/${uid}`);
          onValue(dbRef, (snapshot) => {
            let result = snapshot.val();
            setUserDetail(result);
          });
        } else {
        }
      });
    };
    getUser();
  }, []);

  return (
    <Box className="appbar_wrap" sx={{ flexGrow: 1 }}>
      <AppBar className="appbar" position="static">
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            component="h6"
            sx={{
              display: {
                sm: "block",
                color: "#252733",
                fontSize: "24px",
                fontWeight: "800",
              },
            }}
          >
            {title}
          </Typography>

          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{ display: { xs: "flex", md: "flex" }, alignItems: "center" }}
          >
            <IconButton size="large" color="inherit">
              <Badge color="error">
                <SearchIcon sx={{ fill: "#c5c7cd" }} />
              </Badge>
            </IconButton>
            <IconButton
              size="large"
              aria-label="show 1 new notifications"
              color="inherit"
            >
              <Badge badgeContent={""} color="error">
                <NotificationsIcon sx={{ fill: "#c5c7cd" }} />
              </Badge>
            </IconButton>
            <Box className="verticle_divider"></Box>

            <Typography
              sx={{ color: "#000", fontSize: "0.875rem" }}
              className="app_username"
              variant="h6"
              component="h2"
            >
              {/* Username */}
              {userDetail === null ? (
                <CircularProgress />
              ) : (
                userDetail.name.toUpperCase()
              )}
            </Typography>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
              className="avatar_img"
            >
              <img src={avatar} alt="" />
            </IconButton>
            <Box sx={{ display: { xs: "flex" } }}></Box>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </Box>
  );
}
