import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import Paper from "@mui/material/Paper";
import { Icon } from "@mui/material";
import { Box, Button, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import PrimarySearchAppBar from "../../layout/appbar";
import { getDatabase, ref, onValue } from "firebase/database";
import Spinner from "utils/Spinner/Spinner";

// Assets
import sortIcon from "../../assets/icons/svgs/sortIcon.svg";
import filterIcon from "../../assets/icons/svgs/filterIcon.svg";
import arrowLeftIcon from "../../assets/icons/svgs/arrowLeftIcon.svg";
import avatar from "../../assets/images/pngs/avatar.png";
import "./style.css";

const columns = [
  {
    id: "userImg",
    label: "",
  },
  {
    id: "name",
    label: "Property address",
  },
  { id: "code", label: "Seller name" },
  {
    id: "population",
    label: "Target close date",
    minWidth: 170,
  },
  {
    id: "size",
    label: "Priority",
    minWidth: 170,
  },
];

function createData(userImg, name, code, population, size) {
  const density = population / size;
  return { userImg, name, code, population, size, density };
}

const TitleOrderDetail = ({}) => {
  const addSortIcon = (
    <Icon>
      <img src={sortIcon} alt="sortIcon" />
    </Icon>
  );

  const addFilterIcon = (
    <Icon>
      <img src={filterIcon} alt="filterIcon" />
    </Icon>
  );

  const [orderDetails, setOrderDetails] = React.useState({});
  //
  const [isLoadingOrder, setIsLoadingOrder] = useState(true);

  const params = useParams();

  const rows = [
    createData(
      <Box sx={{ width: "15px" }}>
        <img src={avatar} alt="avatar" />
      </Box>,
      <Box display={"flex"} alignItems={"center"}>
        <Box display={"flex"} flexDirection={"column"}>
          <h2>{orderDetails.propertyAddress}</h2>
          <p>Updated {orderDetails.propertyAddTime}</p>
        </Box>
      </Box>,
      <Box display={"flex"} flexDirection={"column"}>
        <h2>{orderDetails.sellerName}</h2>
        <p>{orderDetails.sellerDate}</p>
      </Box>,
      <Box display={"flex"} flexDirection={"column"}>
        <h2>
          {typeof orderDetails.closeDate === "string"
            ? orderDetails?.closeDate
            : typeof orderDetails.closeDate === "object"
            ? new Date(orderDetails?.closeDate?.$d).toDateString()
            : ""}
        </h2>
        <p>{orderDetails.sellerTime}</p>
      </Box>,
      <Box
        className="priority_"
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Button
          className={`${
            orderDetails.priority === "High"
              ? "bg-deep-carmine-pink"
              : orderDetails.priority === "Medium"
              ? "bg-green"
              : orderDetails.priority == "Low"
              ? "bg-orange-yellow"
              : ""
          } form-btn br-100 bg-deep-carmine-pink text-white`}
          sx={{ minWidth: "max-content", width: "max-content" }}
        >
          {orderDetails.priority}
        </Button>

        <Link to={`/dashboard/title-orders/edit/${params.orderId}`}>
          <Button
            // onClick={handleOpen}
            padding={"0"}
            sx={{ minWidth: "max-content", width: "max-content" }}
          >
            <EditIcon />
          </Button>
        </Link>
      </Box>
    ),
  ];

  useEffect(() => {
    if (!params.orderId) return;
    setIsLoadingOrder(true);

    const db = getDatabase();

    const docsRef = ref(db, "orders-details/" + params.orderId);

    onValue(docsRef, (snapshot) => {
      const data = snapshot.val();
      setOrderDetails(data);
      setIsLoadingOrder(false);
    });
  }, [params]);

  return (
    <React.Fragment>
      <PrimarySearchAppBar title={"Orders"} />
      <Paper
        className="order_table_wrapper order_details"
        sx={{ width: "100%", overflow: "hidden", position: "relative" }}
        elevation={0}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          padding={"20px"}
        >
          <Box className="" display={"flex"} flexDirection={"column"}>
            <Typography className="fw-400" variant="h5" component="h1">
              Order Detail
            </Typography>
            <Typography className="fw-700" variant="h6" component="p">
              {orderDetails.propertyAddress}
            </Typography>
          </Box>
          <Box>
            <Button className="fw-600" startIcon={addSortIcon}>
              Sort
            </Button>
            <Button className="fw-600" ml={"10px"} startIcon={addFilterIcon}>
              Filter
            </Button>
          </Box>
        </Box>
        {isLoadingOrder ? (
          <Box
            mt={5}
            width="100%"
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            margin={"0 auto"}
          >
            <Spinner loader={true} />
          </Box>
        ) : (
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <Link to="/dashboard/title-orders" className="back_btn fw-400">
          <img src={arrowLeftIcon} alt="arrowLeftIcon" />
          Back
        </Link>
      </Paper>
    </React.Fragment>
  );
};

export default TitleOrderDetail;
