import * as React from "react";
import "./style.css";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function OrderFormSelect({ changeEvent, priority }) {
  // const [age, setAge] = React.useState("");

  // const handleChange = (event) => {
  //   setAge(event.target.value);
  // };

  return (
    <FormControl
      className="full_input priority_select"
      sx={{ minWidth: 120 }}
      size="medium"
    >
      <label htmlFor="demo-select-small">Priority</label>
      <Select
        labelId="demo-select-small"
        className="form_field form_input_light_bg"
        id="demo-select-small"
        value={priority}
        name="priority"
        onChange={changeEvent}
      >
        {/* <MenuItem value="">
          <em>None</em>
        </MenuItem> */}
        <MenuItem value={"High"}>High</MenuItem>
        <MenuItem value={"Medium"}>Medium</MenuItem>
        <MenuItem value={"Low"}>Low</MenuItem>
      </Select>
    </FormControl>
  );
}
