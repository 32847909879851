import React from "react";
import "./style.css";
import comingsoonLoader from "../../assets/images/pngs/comingsoon.gif";
import { Box } from "@mui/material";

const ComingSoon = () => {
  return (
    <Box className="comingsoon_wrap">
      <img src={comingsoonLoader} alt="coming soon" />
    </Box>
  );
};

export default ComingSoon;
