import React from 'react';

import styles from './styles.module.scss';

const Title = ({ text }) => {
    return (
        <p className={ styles.title }>
            { text }
        </p>
    );
}

export { Title };
