import React, { useState, useLayoutEffect } from "react";
import { Navigate, useLocation } from "react-router";
import { Link } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
// Formik
import { useFormik } from "formik";
import { auth } from "../../../config/firebaseConfig";
import { sendPasswordResetEmail } from "firebase/auth";
import * as yup from "yup";
// Toasters
import { toast } from "react-toastify";
// Spinner
import Spinner from "../../../utils/Spinner/Spinner";
import CircularProgress from "@mui/material/CircularProgress";

const theme = createTheme();

export default function ForgetScreen({ isUser, isLoading }) {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [loading, setLoading] = useState(false);
  let location = useLocation();

  const { from } = location.state || {
    from: { pathname: "/dashboard/overview" },
  };

  let formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: yup.object().shape({
      email: yup.string().email("Invalid email").required("Required"),
    }),
    onSubmit: (value) => {
      setLoading(true);
      sendPasswordResetEmail(auth, value.email)
        .then((res) => {
          setLoading(false);
          toast.success(
            "Email has been sent in to your email so you can easily set your new password!"
          );
        })
        .catch((error) => {
          if (error.code === "auth/user-not-found") {
            toast.error("User email not found");
          } else {
            toast.error(error.message);
          }
          setLoading(false);
        });
    },
  });

  if (isLoading) return <Spinner />;
  if (isUser) return <Navigate to={from?.pathname} replace />;

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" className="form-wrap">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Forgot Password
          </Typography>
          <Box
            component="form"
            onSubmit={formik.handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={formik.values.email}
              onChange={formik.handleChange}
            />
            <Button
              type="submit"
              fullWidth
              className="bg--purple-blue text-white"
              disabled={loading}
              sx={{ mt: 3, mb: 2 }}
            >
              {loading ? <CircularProgress /> : "Send"}
            </Button>
            <Grid mt={2} textAlign={"center"} item xs={12}>
              <Link to="/login" className="form-link" variant="body2">
                {"Back to Signin"}
              </Link>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
