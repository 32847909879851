import React from 'react';

import { Modal } from '../../Modal';
import checkGreen from 'assets/icons/check_green.svg'
import checkGrey from 'assets/icons/check_grey.svg'
import styles from './styles.module.scss';
// import { PricingItemProps } from "../../../types/pricingItem";

const PricingItem = ({ btnType, isNew, price, subtitle, title }) => {
    const [isModalActive, setIsModalActive] = React.useState(false);

    return (
        <div className={ isNew ? styles.pricing_item__new : styles.pricing_item }>
            <Modal isActive={ isModalActive } setIsActive={ setIsModalActive }/>
            <p className={ styles.pricing_item__title }>
                { title }
            </p>
            <p className={ styles.pricing_item__subtitle }>
                { subtitle }
            </p>
            <div className={ styles.pricing_item__price }>
                <p>${ price }/user</p>
                <span>Per Month</span>
            </div>
            <button className={ styles.pricing_item__btn } onClick={ () => setIsModalActive(true) }>
                { btnType }
            </button>
            {
                title === 'Basic' &&

                <div className={ styles.pricing_item__checklist }>
                    <div className={ styles.pricing_item__check }>
                        <div className={ styles.pricing_item__check_image }>
                            <img src={ checkGreen } alt="img"/>
                        </div>
                        <p className={ styles.pricing_item__check_text }>
                            Powerful collaboration
                        </p>
                    </div>
                    <div className={ styles.pricing_item__check }>
                        <div className={ styles.pricing_item__check_image }>
                            <img src={ checkGreen } alt="img"/>
                        </div>
                        <p className={ styles.pricing_item__check_text }>
                            Secure messaging
                        </p>
                    </div>
                    <div className={ styles.pricing_item__check }>
                        <div className={ styles.pricing_item__check_image }>
                            <img src={ checkGreen } alt="img"/>
                        </div>
                        <p className={ styles.pricing_item__check_text }>
                            SMS notifications
                        </p>
                    </div>
                    <div className={ styles.pricing_item__check }>
                        <div className={ styles.pricing_item__check_image }>
                            <img src={ checkGreen } alt="img"/>
                        </div>
                        <p className={ styles.pricing_item__check_text }>
                            Document management
                        </p>
                    </div>
                    <div className={ styles.pricing_item__check }>
                        <div className={ styles.pricing_item__check_image }>
                            <img src={ checkGrey } alt="img"/>
                        </div>
                        <p className={ styles.pricing_item__check_text }>
                            Up to 100 closings per month per user*
                        </p>
                    </div>
                </div>
            }
            {
                title === 'Pro' &&

                <div className={ styles.pricing_item__checklist }>
                    <div className={ styles.pricing_item__check }>
                        <div className={ styles.pricing_item__check_image }>
                            <img src={ checkGreen } alt="img"/>
                        </div>
                        <p className={ styles.pricing_item__check_text }>
                            Private labeled (branded)
                        </p>
                    </div>
                    <div className={ styles.pricing_item__check }>
                        <div className={ styles.pricing_item__check_image }>
                            <img src={ checkGreen } alt="img"/>
                        </div>
                        <p className={ styles.pricing_item__check_text }>
                            Unlimited closings
                        </p>
                    </div>
                    <div className={ styles.pricing_item__check }>
                        <div className={ styles.pricing_item__check_image }>
                            <img src={ checkGreen } alt="img"/>
                        </div>
                        <p className={ styles.pricing_item__check_text }>
                            Advanced features
                        </p>
                    </div>
                    <div className={ styles.pricing_item__check }>
                        <div className={ styles.pricing_item__check_image }>
                            <img src={ checkGreen } alt="img"/>
                        </div>
                        <p className={ styles.pricing_item__check_text }>
                            All “Basic” plan features
                        </p>
                    </div>
                    <div className={ styles.pricing_item__check }>
                        <div className={ styles.pricing_item__check_image }>
                            <img src={ checkGreen } alt="img"/>
                        </div>
                        <p className={ styles.pricing_item__check_text }>
                            Premium training & phone support
                        </p>
                    </div>
                </div>
            }
        </div>
    );
}

export { PricingItem };
