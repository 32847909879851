import * as React from "react";
import "./Spinner.css";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

export default function Spinner({ loader }) {
  return (
    <>
      {!loader ? (
        <Box className="spinner_wrap" sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      ) : (
        <CircularProgress />
      )}
    </>
  );
}
