import React, { useState, useLayoutEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { auth } from "../../../config/firebaseConfig";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { signInWithEmailAndPassword } from "firebase/auth";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
// Toasters
import { toast } from "react-toastify";
// Spinner   
import Spinner from "../../../utils/Spinner/Spinner";

const theme = createTheme();

const credentials = {
  email: "",
  password: "",
};

export default function SignIn({ isLoading, isUser }) {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  let location = useLocation();
  const { from } = location.state || {
    from: { pathname: "/dashboard/overview" },
  };
  const [loading, setLoading] = useState(false);
  const [loginInputs, setLoginInputs] = useState(credentials);
  const handleChange = (e) => {
    setLoginInputs({
      ...loginInputs,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    signInWithEmailAndPassword(auth, loginInputs.email, loginInputs.password)
      .then((userCredential) => {
        const user = userCredential.user;
        setLoading(false);
        navigate("/dashboard/overview");
        toast.success("Login Successfull");
      })
      .catch((error) => {
        if (error.code === "auth/user-not-found") {
          toast.error("There is no user found for this email!");
        } else if (error.code === "auth / invalid - email") {
          toast.error("There is no user found for this email!");
        } else if (error.code === "auth/wrong-password") {
          toast.error("Password is incorrect");
        } else if (error.code === "auth/invalid-email") {
          toast.error("Email is incorrect");
        } else {
          toast.error(error.message);
        }
        console.log(error);
        setLoading(false);
      });
  };

  //
  if (isLoading) return <Spinner />;
  if (isUser) return <Navigate to={from?.pathname} replace />;

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" className="form-wrap">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={loginInputs.email}
              onChange={handleChange}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              value={loginInputs.password}
              autoComplete="current-password"
              onChange={handleChange}
            />
            <Button
              type="submit"
              fullWidth
              className="bg--purple-blue text-white"
              disabled={loading}
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid textAlign={"right"} item xs={12}>
                <Link to="/forget" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid mt={2} textAlign={"center"} item xs={12}>
                <Link to="/signup" className="form-link" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
