import { Box, Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

const Page404 = () => {
  return (
    <Box className="error_page">
      <h1>404 Page not found</h1>
      <Link to="/login">
        <Button variant="contained">Go Back</Button>
      </Link>
    </Box>
  );
};

export default Page404;
