import { Container } from '../Container';
import { FeaturesItem } from './FeaturesItem';
import image1 from 'assets/images/features/01.jpg';
import image2 from 'assets/images/features/02.jpg';
import image3 from 'assets/images/features/03.jpg';
import image4 from 'assets/images/features/04.jpg';
import styles from './styles.module.scss';
import { Subtitle } from '../Subtitle';
import { Title } from '../Title';

const Features = () => {
    return (
        <section className={ styles.features }>
            <Container>
                <Title text="Features & Functionality"/>
                <Subtitle text="Powerful, yet easy to use features"/>
            </Container>
            <div className={ styles.features__row }>
                <FeaturesItem img={ image1 } subtitle="Notifications" title="Automated SMS Alerts"/>
                <FeaturesItem img={ image2 } subtitle="Collaboration" title="In-app messaging"/>
                <FeaturesItem img={ image3 } subtitle="Task Management" title="Borrower checklists"/>
                <FeaturesItem img={ image4 } subtitle="Document Management" title="Upload docs and file"/>
            </div>
        </section>
    );
}

export { Features };
