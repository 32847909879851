// options for below Volume (USD) graph for frontend text display according to their title provided in params.
const options = (name) => {
  return {
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          beginAtZero: true,
          min: 0,
          max: 4,
          stepSize: 1,
          callback: function (value) {
            return value === 0 ? value : value + " " + name;
          },
        },
      },
    },
    plugins: {
      legend: {
        display: true,
      },
      title: {
        display: true,
        text: "Chart.js Line Chart",
      },
      bezierCurve: true,
    },
  };
};

const linearGenerator = (context) => {
  const ctx = context.chart.ctx;
  const gradient = ctx.createLinearGradient(0, 0, 0, 200);
  gradient.addColorStop(0, "#F2F4FF");
  gradient.addColorStop(1, "rgba(73, 156, 214, 0)");
  return gradient;
};

// sample data for Volume (USD) graph
const data = [
  {
    id: 0,
    name: "Day",
    labels: [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
    ],
    datasets: [
      {
        data: [10.6, 6.9, 6.9, 7.6, 2.5, 5.3, 8.8, 12, 11, 10, 9, 8],
        fill: true,
        backgroundColor: linearGenerator,
        borderColor: "rgba(2, 66, 110, 1)",
        borderJoinStyle: "round",
      },
      {
        data: [8.8, 9.4, 5.3, 9, 3.9, 11, 5.1, 9, 6, 10, 7, 9],
        fill: true,
        backgroundColor: linearGenerator,
        borderColor: "#429BD9",
        borderWidth: 3,
        borderDash: [4],
        borderJoinStyle: "round",
      },
    ],
    options: options("H"),
  },
];

const data1 = {
  id: 0,
  name: "Day",
  labels: [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
  ],
  datasets: [
    {
      data: [
        10.6, 6.9, 6.9, 7.6, 2.5, 5.3, 8.8, 12, 11, 10, 9, 8, 10, 2, 5, 7, 4, 1,
        7, 3.11, 4.4,
      ],
      fill: true,
      backgroundColor: linearGenerator,
      borderColor: " #3751FF",
      borderJoinStyle: "round",
      label: "This Month",
      lineTension: 0.5,
    },
    {
      data: [
        8.8, 9.4, 5.3, 9, 3.9, 11, 5.1, 9, 6, 10, 7, 9, 5.3, 9, 3.9, 11, 5.1, 2,
        4, 6, 8, 4,
      ],
      fill: true,
      backgroundColor: linearGenerator,
      borderColor: "#DFE0EB",
      borderWidth: 3,
      // borderDash: [4],
      borderJoinStyle: "round",
      label: "Last Month",
      lineTension: 0.5,
    },
  ],
  options: options("H"),
};

export { data1, options };
