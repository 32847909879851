// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBpMNshg2fbz2eBqQRrUA9f5-J25Xwx8qM",
  authDomain: "closr-6076f.firebaseapp.com",
  projectId: "closr-6076f",
  storageBucket: "closr-6076f.appspot.com",
  messagingSenderId: "396519136248",
  appId: "1:396519136248:web:dceb9e3450552b4594540d",
  measurementId: "G-L0XDKLBBS4",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const database = getDatabase(app);
export { app, auth, database };
