import "./App.css";
import React, { useEffect, useState } from "react";
import AppRoute from "./router/AppRoute";
import { auth } from "./config/firebaseConfig";
import { onAuthStateChanged } from "firebase/auth";
import { ToastContainer } from "react-toastify";
import { ThemeProvider, createTheme } from "@mui/material/styles";

function App() {
  const theme = createTheme({
    typography: {
      fontFamily: "Mulish, sans-serif",
    },
  });
  const [loading, setLoading] = useState(true);
  const [isUser, setIsUser] = useState(false);

  useEffect(() => {
    const getUser = () => {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          const uid = user.uid;
          setLoading(false);
          setIsUser(true);
        } else {
          setLoading(false);
          setIsUser(false);
        }
      });
    };
    getUser();
  }, []);

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <AppRoute isUser={isUser} loading={loading} />
        <ToastContainer
          position="bottom-right"
          autoClose={2500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
