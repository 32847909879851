import React, { useLayoutEffect } from "react";
import { Box } from "@mui/system";
import ComingSoon from "../../utils/loader/ComingSoon";

const MessageScreen = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box>
      <ComingSoon />
    </Box>
  );
};

export default MessageScreen;
