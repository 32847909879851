import { Container } from '../Container';
import facebook from 'assets/icons/facebook.svg';
import instagram from 'assets/icons/instagram.svg';
import twitter from 'assets/icons/twitter.svg';
import styles from './styles.module.scss';

const Footer = () => {
    return (
        <footer className={ styles.footer }>
            <Container>
                <div className={ styles.footer_top }>
                    <div className={ styles.footer_top__left }>
                        <p className={ styles.footer_top__title }>
                            Get In Touch
                        </p>
                        <p className={ styles.footer_top__subtitle }>
                            Empowering closing attorneys and title companies
                        </p>
                        <div className={ styles.footer_top__socials }>
                            <a className={ styles.footer_top__icon }>
                                <img src={ facebook } alt="facebook image"/>
                            </a>
                            <a className={ styles.footer_top__icon }>
                                <img src={ instagram } alt="instagram image"/>
                            </a>
                            <a className={ styles.footer_top__icon }>
                                <img src={ twitter } alt="twitter image"/>
                            </a>
                        </div>
                    </div>
                    <div className={ styles.footer_top__right }>
                        <div className={ styles.footer_top__column }>
                            <p className={ styles.footer_top__title }>
                                Features
                            </p>
                            <ul className={ styles.footer_top__list }>
                                <li className={ styles.footer_top__link }>
                                    <a>API for MLS</a>
                                </li>
                                <li className={ styles.footer_top__link }>
                                    <a>User Analytic</a>
                                </li>
                                <li className={ styles.footer_top__link }>
                                    <a>Agent tools</a>
                                </li>
                                <li className={ styles.footer_top__link }>
                                    <a>Product features</a>
                                </li>
                            </ul>
                        </div>
                        <div className={ styles.footer_top__column }>
                            <p className={ styles.footer_top__title }>
                                Resources
                            </p>
                            <ul className={ styles.footer_top__list }>
                                <li className={ styles.footer_top__link }>
                                    <a>API Docs</a>
                                </li>
                                <li className={ styles.footer_top__link }>
                                    <a>Brokers</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Container>
            <div className={ styles.footer_bottom }>
                Closr, LLC 2021. All Right Reserved.
            </div>
        </footer>
    );
}

export { Footer };
