import { useForm, ValidationError } from "@formspree/react";
//
import styles from "./styles.module.scss";

const Modal = ({ isActive, setIsActive }) => {
  const [state, handleSubmit] = useForm("PopUpForm", {
    data: {
      _subject: "New request!",
    },
  });

  isActive
    ? document.body.classList.add("lock_pop")
    : document.body.classList.remove("lock_pop");

  return (
    <div
      className={isActive ? styles.modal__active : styles.modal}
      onClick={() => setIsActive(false)}
    >
      <div
        className={
          isActive ? styles.modal_content__active : styles.modal_content
        }
        onClick={(e) => e.stopPropagation()}
      >
        <span className={styles.close} onClick={() => setIsActive(false)}>
          x
        </span>
        <form className={styles.modal__form} onSubmit={handleSubmit}>
          <label htmlFor="name" className={styles.modal__label}>
            Name*
            <input
              id="name"
              type="text"
              name="Name"
              placeholder="Enter your name"
              required={true}
              className={styles.modal__input}
            />
          </label>
          <label htmlFor="email" className={styles.modal__label}>
            Email*
            <input
              id="email"
              type="email"
              name="Email"
              placeholder="Enter your email"
              required={true}
              className={styles.modal__input}
            />
          </label>
          <label htmlFor="company" className={styles.modal__label}>
            Company*
            <input
              id="company"
              type="text"
              name="Company"
              placeholder="Enter your company"
              required={true}
              className={styles.modal__input}
            />
          </label>
          <label htmlFor="phone" className={styles.modal__label}>
            Phone
            <input
              id="phone"
              type="number"
              name="Phone"
              placeholder="Enter your phone"
              className={styles.modal__input}
            />
          </label>
          <label htmlFor="message" className={styles.modal__label}>
            Message
            <textarea
              name="Message"
              id="message"
              placeholder="Enter your message"
              className={styles.modal__input}
            />
          </label>
          <button
            type="submit"
            disabled={state.submitting}
            className={styles.modal__btn}
          >
            Submit
          </button>
          <div className={styles.error}>
            <ValidationError
              field="Email"
              prefix="Email"
              errors={state.errors}
            />
          </div>
          {state.succeeded && <div className={styles.success}>Success</div>}
        </form>
      </div>
    </div>
  );
};

export { Modal };
