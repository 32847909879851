import React from 'react';

import { Modal } from '../Modal';
import blob from 'assets/images/blob.png';
import styles from './styles.module.scss';

const Infoblock = () => {
    const [isModalActive, setIsModalActive] = React.useState(false);

    return (
        <section className={ styles.infoblock }>
            <Modal isActive={ isModalActive } setIsActive={ setIsModalActive }/>
            <div className={ styles.infoblock_content }>
                <p className={ styles.infoblock_content__title }>
                    The new standard in real estate closings
                </p>
                <p className={ styles.infoblock_content__text }>
                    Empowering title companies and attorneys to deliver seamless title orders
                </p>
                <button className={ styles.infoblock_content__btn } onClick={ () => setIsModalActive(true) }>
                    Get early access
                </button>
            </div>
            <div className={ styles.infoblock_image }>
                <img src={ blob } alt="blob img"/>
            </div>
        </section>
    );
}

export { Infoblock };
