import React from 'react';

import { Container } from '../Container';
import { SaveTimeItem } from './SaveTimeItem';
import styles from './styles.module.scss';
import { Subtitle } from '../Subtitle';
import { Title } from '../Title';

const SaveTime = () => {
    return (
        <section className={ styles.savetime }>
            <Container>
                <Title text="Better Communication, More Time Savings, Less Errors"/>
                <Subtitle text="Save time and deliver a more secure closing experience"/>
                <div className={ styles.savetime__row }>
                    <SaveTimeItem text="Agents" isCentral={ false }/>
                    <SaveTimeItem text="Title Companies & Real Estate Attorneys" isCentral={ true }/>
                    <SaveTimeItem text="Borrowers" isCentral={ false }/>
                </div>
            </Container>
        </section>
    );
}

export { SaveTime };
