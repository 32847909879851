import React, { useLayoutEffect } from "react";
import OrderTable from "../../components/table/orderTable";
import PrimarySearchAppBar from "../../layout/appbar";
import "./style.css";

const TitleOrders = ({ editingOrder, setEditingOrder }) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <PrimarySearchAppBar title={"Orders"} />
      <OrderTable
        editingOrder={editingOrder}
        setEditingOrder={setEditingOrder}
      />
    </React.Fragment>
  );
};

export default TitleOrders;
