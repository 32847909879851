import { Routes, Route, Navigate, useLocation, Outlet } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Page404 from "../components/errorShow/Page404";
import ForgetScreen from "../components/forms/forget";
import SignIn from "../components/forms/login";
import NewOrderForm from "../components/forms/newOrderForm";
import SignUp from "../components/forms/signup";
import Sidebar from "../layout/sidebar";
import CalenderScreen from "../pages/calenderScreen";
import Contact from "../pages/contact";
import DocsScreen from "../pages/docsScreen";
import Home from "../pages/Home";
import MessageScreen from "../pages/message";
import Overview from "../pages/overview";
import TaskScreen from "../pages/tasksScreen";
import TitleOrders from "../pages/titleOrders";
import TitleOrderDetail from "../pages/titleOrders/TitleOrderDetail";
import Spinner from "../utils/Spinner/Spinner";

const AppRoute = ({ isUser, loading }) => {
  return (
    <div className="_main">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Navigate to="/" />} />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute
              isUser={isUser}
              loading={loading}
              redirectPath={"/login"}
            >
              <Sidebar />
            </ProtectedRoute>
          }
        >
          <Route index element={<Navigate to="overview" />} />
          <Route path="overview" element={<Overview />} />
          <Route path="title-orders" element={<TitleOrders />} />
          <Route
            path="title-orders/details/:orderId"
            element={<TitleOrderDetail />}
          />
          <Route path="title-orders/new-order" element={<NewOrderForm />} />
          <Route
            path="title-orders/edit/:orderId"
            element={<NewOrderForm editForm />}
          />
          <Route path="message" element={<MessageScreen />} />
          <Route path="contact" element={<Contact />} />
          <Route path="tasks" element={<TaskScreen />} />
          <Route path="docs" element={<DocsScreen />} />
          <Route path="calender" element={<CalenderScreen />} />
        </Route>
        <Route
          path="/login"
          element={<SignIn isUser={isUser} isLoading={loading} />}
        />
        <Route
          path="/signup"
          element={<SignUp isUser={isUser} isLoading={loading} />}
        />
        <Route
          path="/forget"
          element={<ForgetScreen isUser={isUser} isLoading={loading} />}
        />
        <Route path="*" element={<Page404 isUser={isUser} />} />
      </Routes>
    </div>
  );
};

const ProtectedRoute = ({ redirectPath = "/", children, isUser, loading }) => {
  let location = useLocation();

  // if auth loading
  if (loading) {
    return <Spinner />;
  }
  // if user is not authenticated
  if (!isUser) {
    return <Navigate to={redirectPath} replace state={{ from: location }} />;
  }
  return children ? children : <Outlet />;
};

export default AppRoute;
