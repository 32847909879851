import * as React from "react";
import { auth } from "../../config/firebaseConfig";
import { Outlet, useNavigate, useLocation } from "react-router";
import "./style.css";
import { signOut } from "firebase/auth";
import PropTypes from "prop-types";
import { Box, Button } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";

// Assets
import logo from "../../assets/images/svgs/logo.svg";
import overviewIcon from "../../assets/icons/svgs/overviewIcon.svg";
import titleOrderIcon from "../../assets/icons/svgs/titleOrderIcon.svg";
import messagesIcon from "../../assets/icons/svgs/messagesIcon.svg";
import contactsIcon from "../../assets/icons/svgs/contactsIcon.svg";
import tasksIcon from "../../assets/icons/svgs/tasksIcon.svg";
import docsIcon from "../../assets/icons/svgs/docsIcon.svg";
import calenderIcon from "../../assets/icons/svgs/calenderIcon.svg";
import settingsIcon from "../../assets/icons/svgs/settingsIcon.svg";
import { AppBar } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
const drawerWidth = 240;

const menuItemsData = [
  {
    name: "Overview",
    menuText: "/",
    icon: overviewIcon,
    route: "overview",
  },
  {
    name: "Title Order",
    menuText: "titleorders",
    icon: titleOrderIcon,
    route: "title-orders",
  },
  {
    name: "Messages",
    menuText: "messages",
    icon: messagesIcon,
    route: "message",
  },
  {
    name: "Contacts",
    menuText: "contacts",
    icon: contactsIcon,
    route: "contact",
  },
  {
    name: "Tasks",
    menuText: "tasks",
    icon: tasksIcon,
    route: "tasks",
  },
  {
    name: "Docs",
    menuText: "docs",
    icon: docsIcon,
    route: "docs",
  },
  {
    name: "Calendar",
    menuText: "calender",
    icon: calenderIcon,
    route: "calender",
  },
];

function Sidebar(props) {
  const { window } = props;
  const navigate = useNavigate("/");
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [sideMenuData, setSideMenuData] = React.useState("/");
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = () => {
    signOut(auth).then(() => {
      navigate("/login");
    });
  };

  const drawer = (
    <div className="sidebar_wrap">
      <img className="sidebar_logo" src={logo} alt="closer" />
      <Toolbar />
      <Box className="menulist_wrap">
        {menuItemsData.map((d, i) => {
          return (
            <List
              key={i}
              className={`menulist ${
                location.pathname.includes(d.route)
                  ? "active_list"
                  : "nonactive_list"
              }`}
            >
              <ListItem
                disablePadding
                onClick={() => {
                  navigate(d.route);
                  setMobileOpen(false);
                }}
              >
                <ListItemButton>
                  <ListItemIcon sx={{ minWidth: "38px" }}>
                    <img src={d.icon} alt={d.name} />
                  </ListItemIcon>
                  <ListItemText className="list_item_text" primary={d.name} />
                </ListItemButton>
              </ListItem>
            </List>
          );
        })}
      </Box>
      <Divider className="sidebar_divider" />

      <List sx={{ marginTop: "auto" }}>
        <ListItem>
          <Button
            sx={{ color: "#a4a6b3", width: "100%", backgroundColor: "#1a1a2f" }}
            onClick={handleLogout}
          >
            Logout
          </Button>
        </ListItem>
        <ListItem>
          <ListItemButton>
            <ListItemIcon sx={{ minWidth: "38px" }}>
              <img src={settingsIcon} alt={"dasd"} />
            </ListItemIcon>
            <ListItemText className="nonactive_list" primary={"Settings"} />
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {/* AppBar */}
      <AppBar
        position="fixed"
        className="mb_appbar"
        sx={{
          width: { sm: `` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: "block", md: "none" } }}
        >
          <MenuIcon />
        </IconButton>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          className="sidebar"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "#0d0c22",
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          className="sidebar"
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "#0d0c22",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
}

Sidebar.propTypes = {
  window: PropTypes.func,
};

export default Sidebar;
