import React,{useLayoutEffect} from "react";
import { Box } from "@mui/system";
import ComingSoon from "../../utils/loader/ComingSoon";

const TaskScreen = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box>
      <ComingSoon />
    </Box>
  );
};

export default TaskScreen;
