import * as React from "react";
import "./style.css";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Box } from "@mui/material";

export default function CloseDatePicker({ closeDate, changeEvent }) {
  return (
    <LocalizationProvider className="full_input" dateAdapter={AdapterDayjs}>
      <Box className="full_input" display={"flex"} flexDirection={"column"}>
        <label htmlFor="order-form-date">Close date</label>
        <DatePicker
          className="form-date-picker form_field form_input_light_bg"
          // label="Basic example"
          id="order-form-date"
          value={closeDate}
          // onChange={(newValue) => {
          //   setValue(newValue);
          // }}
          onChange={(newValue) => changeEvent(newValue)}
          renderInput={(params) => <TextField {...params} />}
        />
      </Box>
    </LocalizationProvider>
  );
}
