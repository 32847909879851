import { FormspreeProvider } from '@formspree/react';
import { Header } from './components/Header';
import { Infoblock } from './components/Infoblock';
import { SaveTime } from './components/SaveTime';
import { Features } from './components/Features';
import { Testimonials } from './components/Testimonials';
import { Pricing } from './components/Pricing';
import { EarlyAccess } from './components/EarlyAccess';
import { Footer } from './components/Footer';

const Home = () => {
    return (
        <FormspreeProvider project={process.env.REACT_APP_FORMSPREE_PROJECT_KEY}>
            <Header />
            <Infoblock />
            <SaveTime />
            <Features />
            <Testimonials />
            <Pricing />
            <EarlyAccess />
            <Footer />
        </FormspreeProvider>
    )
}

export default Home