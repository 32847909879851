import React, { useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import { Line } from "react-chartjs-2";
import { Box, Button } from "@mui/material";
import Input from "@mui/material/Input";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Icon } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2"; // Grid version 2
import TopAppBar from "../../layout/appbar";
import Typography from "@mui/material/Typography";
import { Chart as ChartJS } from "chart.js/auto";

import "./style.css";
import { data1 } from "./data";

// Assets
import newOrderIcon from "../../assets/icons/svgs/newOrderIcon.svg";
import addIcon from "../../assets/icons/svgs/addIcon.svg";

const Overview = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const addNewOrderIcon = (
    <Icon>
      <img src={newOrderIcon} alt="newOrderIcon" />
    </Icon>
  );
  const addTaskIcon = (
    <Icon>
      <img src={addIcon} alt="addIcon" />
    </Icon>
  );
  return (
    <Box className="main-wrap">
      <TopAppBar />
      <Box
        className="new_order_wrap"
        mt={4}
        display={"flex"}
        flexWrap={"wrap"}
        justifyContent={"space-between"}
      >
        <Box display={"flex"} alignItems={"center"}>
          <Box className="no_box">
            <Typography variant="h1" component="h2">
              New Orders This <br /> Month
            </Typography>
            <Typography variant="h3" component="strong">
              27
            </Typography>
          </Box>
          <Box className="no_box mo-l-5">
            <Typography variant="h1" component="h2">
              New Orders This
              <br />
              Year
            </Typography>
            <Typography variant="h3" component="strong">
              138
            </Typography>
          </Box>
        </Box>
        <Link to="/dashboard/title-orders/new-order">
          <Button startIcon={addNewOrderIcon}>New Order</Button>
        </Link>
      </Box>
      <Box className="chart_wrap" mt={2} borderRadius={"8px"}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          flexWrap={"wrap"}
          // alignItems={"center"}
        >
          <Box className="chart_box" display={"flex"} flexDirection={"column"}>
            <Box mb={5} display={"flex"} flexDirection={"column"}>
              <Typography className="fw-600" variant="h5" component="h2">
                This month’s trends
              </Typography>
              <Typography className="fw-400" variant="h5" component="p">
                as of May 18, 2021
              </Typography>
            </Box>
            <Line
              data={data1}
              // options={options("H")}
            />
          </Box>
          <Box className="box_wrap">
            <Box className="month_box" textAlign={"center"}>
              <Typography className="fw-600" variant="h5" component="h2">
                Total closings this month
              </Typography>
              <Typography className="fw-700" variant="h5" component="p">
                49
              </Typography>
              <Box mt={3} mb={3} className="divider"></Box>
            </Box>
            <Box className="month_box" textAlign={"center"}>
              <Typography className="fw-600" variant="h5" component="h2">
                Received
              </Typography>
              <Typography className="fw-700" variant="h5" component="p">
                67
              </Typography>
              <Box mt={3} mb={3} className="divider"></Box>
            </Box>
            <Box className="month_box" textAlign={"center"}>
              <Typography className="fw-600" variant="h5" component="h2">
                Average first response time
              </Typography>
              <Typography className="fw-700" variant="h5" component="p">
                33m
              </Typography>
              <Box mt={3} mb={3} className="divider"></Box>
            </Box>
            <Box className="month_box" textAlign={"center"}>
              <Typography className="fw-600" variant="h5" component="h2">
                Average response time
              </Typography>
              <Typography className="fw-700" variant="h5" component="p">
                3h 8m
              </Typography>
              <Box mt={3} mb={3} className="divider"></Box>
            </Box>
            <Box className="month_box" textAlign={"center"}>
              <Typography className="fw-600" variant="h5" component="h2">
                Average tasks per day
              </Typography>
              <Typography className="fw-700" variant="h5" component="p">
                188
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Grid2
        mt={"2rem"}
        container
        rowSpacing={1}
        columnSpacing={4}
        // columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        <Grid2 xs={12} sm={12} md={6}>
          <Box className="view_box">
            <Grid2 container>
              <Grid2 xs={7}>
                <Box className="vb vb_1">
                  <Typography className="fw-700" variant="h5" component="h2">
                    Closings this week
                  </Typography>
                  <Typography
                    mt={1}
                    className="fw-400"
                    variant="h6"
                    component="p"
                  >
                    Group: Pre-closers
                  </Typography>
                </Box>
              </Grid2>
              <Grid2 xs={5}>
                <Box className="vb" textAlign={"right"}>
                  <Link className="fw-600" to="/">
                    View details
                  </Link>
                </Box>
              </Grid2>
            </Grid2>

            <Grid2 container>
              <Grid2 xs={12}>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  className="vb vb_2"
                >
                  <Typography className="fw-600" variant="h6" component="p">
                    Johnson / 7211 Orange Blossom Ave; Atlanta, GA
                  </Typography>
                  <Typography className="fw-600" variant="h6" component="span">
                    Monday
                  </Typography>
                </Box>
              </Grid2>
            </Grid2>

            <Box className="divider"></Box>
            <Grid2 container>
              <Grid2 xs={12}>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  className="vb vb_2"
                >
                  <Typography className="fw-600" variant="h6" component="p">
                    Frost / 388 Lake Spring Rd; Duluth, GA
                  </Typography>
                  <Typography className="fw-600" variant="h6" component="span">
                    Wednesday
                  </Typography>
                </Box>
              </Grid2>
            </Grid2>
            <Box className="divider"></Box>

            <Grid2 container>
              <Grid2 xs={12}>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  className="vb vb_2"
                >
                  <Typography className="fw-600" variant="h6" component="p">
                    Patel / 292 E. Andrews Ave, Alpharetta, GA
                  </Typography>
                  <Typography className="fw-600" variant="h6" component="span">
                    Wednesday
                  </Typography>
                </Box>
              </Grid2>
            </Grid2>
            <Box className="divider"></Box>
            <Grid2 container>
              <Grid2 xs={12}>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  className="vb vb_2"
                >
                  <Typography className="fw-600" variant="h6" component="p">
                    Nguyen / 1165 Providence Oaks Rd, Sandy Springs, GA
                  </Typography>
                  <Typography className="fw-600" variant="h6" component="span">
                    Thursday
                  </Typography>
                </Box>
              </Grid2>
            </Grid2>
          </Box>
        </Grid2>

        <Grid2 xs={12} sm={12} md={6}>
          <Box className="view_box">
            <Grid2 container>
              <Grid2 xs={7}>
                <Box className="vb vb_1">
                  <Typography className="fw-700" variant="h5" component="h2">
                    Tasks
                  </Typography>
                  <Typography
                    mt={1}
                    className="fw-400"
                    variant="h6"
                    component="p"
                  >
                    Today
                  </Typography>
                </Box>
              </Grid2>
              <Grid2 xs={5}>
                <Box className="vb" textAlign={"right"}>
                  <Link className="fw-600" to="/">
                    View details
                  </Link>
                </Box>
              </Grid2>
            </Grid2>

            <Grid2 container>
              <Grid2 xs={12}>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  className="vb vb_2"
                >
                  <Input
                    disableUnderline={true}
                    placeholder="Create new task"
                  />

                  <Button
                    sx={{
                      minWidth: "max-content",
                      padding: "0",
                      height: "0",
                    }}
                  >
                    {addTaskIcon}
                  </Button>
                </Box>
              </Grid2>
            </Grid2>
            <Box className="divider"></Box>

            <Grid2 container>
              <Grid2 xs={12}>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  className="vb vb_2"
                >
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Get seller’s information - 125 Abernathy Ave."
                    />
                  </FormGroup>
                  <Button className="bg-orange-yellow br-8 form-btn text-white">
                    Urgent
                  </Button>
                </Box>
              </Grid2>
            </Grid2>
            <Box className="divider"></Box>

            <Grid2 container>
              <Grid2 xs={12}>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  className="vb vb_2"
                >
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Get seller’s information - 125 Abernathy Ave."
                    />
                  </FormGroup>
                  <Button className="bg-greenish-cyan br-8 form-btn text-white">
                    Urgent
                  </Button>
                </Box>
              </Grid2>
            </Grid2>
            <Box className="divider"></Box>

            <Grid2 container>
              <Grid2 xs={12}>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  className="vb vb_2"
                >
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Get seller’s information - 125 Abernathy Ave."
                    />
                  </FormGroup>
                  <Button className="bg-porcelain br-8 form-btn text-santa-gray">
                    Urgent
                  </Button>
                </Box>
              </Grid2>
            </Grid2>
          </Box>
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default Overview;
