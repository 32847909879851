import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Icon } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Box, Button, Typography } from "@mui/material";
import { getDatabase, ref, onValue } from "firebase/database";
import { getAuth, onAuthStateChanged } from "firebase/auth";

// Assets
import sortIcon from "../../../assets/icons/svgs/sortIcon.svg";
import filterIcon from "../../../assets/icons/svgs/filterIcon.svg";
import moreIcon from "../../../assets/icons/svgs/moreIcon.svg";
import avatar from "../../../assets/images/pngs/avatar.png";
import "./style.css";

const columns = [
  {
    id: "userImg",
    label: "",
  },
  {
    id: "name",
    label: "Property address",
  },
  { id: "code", label: "Seller name" },
  {
    id: "population",
    label: "Target close date",
    minWidth: 170,
  },
  {
    id: "size",
    label: "Priority",
    minWidth: 170,
  },
];

function createData(userImg, name, code, population, size, obj) {
  const density = population / size;
  return { userImg, name, code, population, size, density, obj };
}

export default function OrderTable({}) {
  const [data, setData] = useState([]);

  const addSortIcon = (
    <Icon>
      <img src={sortIcon} alt="sortIcon" />
    </Icon>
  );

  const addFilterIcon = (
    <Icon>
      <img src={filterIcon} alt="filterIcon" />
    </Icon>
  );

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    async function getData() {
      const auth = getAuth();

      await onAuthStateChanged(auth, (user) => {
        if (user) {
          const db = getDatabase();

          const docsRef = ref(db, "orders-details/");
          onValue(docsRef, (snapshot) => {
            const data = snapshot.val();
            const dataKeys = Object.keys(data);
            const dataValues = Object.values(data);

            const filteredData = [];

            for (let i = 0; i < dataKeys.length; i++) {
              if (dataValues[i].userId === user.uid) {
                filteredData.push({
                  key: dataKeys[i],
                  ...dataValues[i],
                });
              }
            }

            const createdRows = filteredData.map((el, idx) => {
              return createData(
                <Box sx={{ width: "15px" }}>
                  <img src={avatar} alt="avatar" />
                </Box>,
                <Box display={"flex"} alignItems={"center"}>
                  <Box display={"flex"} flexDirection={"column"}>
                    <h2>{el.propertyAddress}</h2>
                    <p>Updated {el.propertyAddTime}</p>
                  </Box>
                </Box>,
                <Box display={"flex"} flexDirection={"column"}>
                  <h2>{el.sellerName}</h2>
                  <p>on {el.sellerDate}</p>
                </Box>,
                <Box display={"flex"} flexDirection={"column"}>
                  <h2>{el.closeDate}</h2>
                  <p>{el.sellerTime}</p>
                </Box>,
                <Box
                  className="priority_"
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Button
                    className={`${
                      el.priority === "High"
                        ? "bg-deep-carmine-pink"
                        : el.priority === "Medium"
                        ? "bg-green"
                        : el.priority === "Low"
                        ? "bg-orange-yellow"
                        : ""
                    } form-btn br-100 text-white`}
                    sx={{ minWidth: "max-content", width: "max-content" }}
                  >
                    {el.priority}
                  </Button>

                  <Button
                    padding={"0"}
                    sx={{ minWidth: "max-content", width: "max-content" }}
                    // onClick={}
                  >
                    <img src={moreIcon} alt="" />
                  </Button>
                </Box>,
                el
              );
            });

            setData(createdRows);
          });
        } else {
        }
      });
    }

    getData();
  }, []);

  return (
    <Box className="">
      <Paper
        className="order_table_wrapper"
        sx={{ width: "100%", overflow: "hidden" }}
        elevation={0}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          padding={"20px"}
        >
          <Typography className="fw-700" variant="h5" component="h1">
            All title orders
          </Typography>
          <Box>
            <Button startIcon={addSortIcon}>Sort</Button>
            <Button ml={"10px"} startIcon={addFilterIcon}>
              Filter
            </Button>
          </Box>
        </Box>
        <TableContainer className="table_wrap" sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {
                // rows
                data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              onClick={() => {}}
                            >
                              <Link to={`details/${row.obj.key}`}>
                                {column.format && typeof value === "number"
                                  ? column.format(value)
                                  : value}
                              </Link>
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
              }
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          className="table_pagination"
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
