import React from 'react';

import { Container } from '../Container';
import { PricingItem } from './PricingItem';
import styles from './styles.module.scss';
import { Subtitle } from '../Subtitle';
import { Title } from '../Title';

const Pricing = () => {
    return (
        <section className={ styles.pricing }>
            <Container>
                <Title text="Pricing"/>
                <Subtitle
                    text="Flexible pricing for solo title agents, small attorney offices and large title companies"/>
                <div className={ styles.pricing__row }>
                    <PricingItem
                        btnType="Sign Up"
                        isNew={ false }
                        price={ 5 }
                        subtitle="Solo Title Agent, Attorney or Pre-Closer"
                        title="Basic"
                    />
                    <PricingItem
                        btnType="Sign Up"
                        isNew={ false }
                        price={ 20 }
                        subtitle="Small or medium teams, up to 50 users"
                        title="Pro"
                    />
                    <PricingItem
                        btnType="Get a custom quote"
                        isNew={ true }
                        price={ 25 }
                        subtitle="Multiple offices or 50+ users"
                        title="Enterprise"
                    />
                </div>
            </Container>
        </section>
    );
}

export { Pricing };
