import React from 'react';

import styles from './styles.module.scss';

const Subtitle = ({ text }) => {
    return (
        <p className={ styles.subtitle }>
            { text }
        </p>
    );
}

export { Subtitle };
