import React from 'react';

import blue from 'assets/icons/saveTime_blue.svg';
import white from 'assets/icons/saveTime_white.svg';
import styles from './style.module.scss';

const SaveTimeItem = ({ isCentral, text }) => {
    return (
        <div className={ isCentral ? styles.savetime_item__central : styles.savetime_item }>
            <div className={ styles.savetime_item__image }>
                <img src={ isCentral ? white : blue } alt="img"/>
            </div>
            <p className={ isCentral ? styles.savetime_item__text__central : styles.savetime_item__text }>
                { text }
            </p>
        </div>
    );
}

export { SaveTimeItem };
