import React from 'react';

import styles from './styles.module.scss';

const FeaturesItem = ({ img, subtitle, title }) => {
    return (
        <div className={ styles.features_item }>
            <div className={ styles.filter }></div>
            <div className={ styles.features_item__image }>
                <img src={ img } alt="img"/>
            </div>
            <div className={ styles.features_item__content }>
                <p className={ styles.features_item__subtitle }>
                    { subtitle }
                </p>
                <p className={ styles.features_item__title }>
                    { title }
                </p>
            </div>
        </div>
    );
}

export { FeaturesItem };
